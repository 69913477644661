var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',[_c('v-card',{attrs:{"flat":""}},[_c('v-sheet',[_c('v-tabs',{attrs:{"dark":_vm.$store.getters.getColorPalette().isDark,"background-color":_vm.$store.getters.getColorPalette().backgroundColorCode,"show-arrows":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tabs-slider',{attrs:{"color":_vm.$store.getters.getColorPalette().accentCode}}),_c('router-link',{style:({
                color: 'inherit',
                display: 'flex',
                'justify-content': 'space-between',
                'align-item': 'center',
                'text-decoration': 'none',
              }),attrs:{"to":'/derived/shifts'}},[_c('v-tab',[_c('v-icon',{staticClass:"mr-2",attrs:{"isDark":_vm.$store.getters.getColorPalette().isDark,"color":_vm.$store.getters.getColorPalette().accentCode,"small":""}},[_vm._v("mdi-clock-time-five-outline")]),_vm._v(_vm._s(_vm.$store.getters.getTextMap().shifts)+" ")],1)],1),_c('router-link',{style:({
                color: 'inherit',
                display: 'flex',
                'justify-content': 'space-between',
                'align-item': 'center',
                'text-decoration': 'none',
              }),attrs:{"to":'/derived/sku'}},[_c('v-tab',[_c('v-icon',{staticClass:"mr-2",attrs:{"isDark":_vm.$store.getters.getColorPalette().isDark,"color":_vm.$store.getters.getColorPalette().accentCode,"small":""}},[_vm._v("mdi-palette-swatch-variant")]),_vm._v(_vm._s(_vm.$store.getters.getTextMap().skus)+" ")],1)],1),_c('router-link',{style:({
                color: 'inherit',
                display: 'flex',
                'justify-content': 'space-between',
                'align-item': 'center',
                'text-decoration': 'none',
              }),attrs:{"to":'/derived/itemCodes'}},[_c('v-tab',[_c('v-icon',{staticClass:"mr-2",attrs:{"isDark":_vm.$store.getters.getColorPalette().isDark,"color":_vm.$store.getters.getColorPalette().accentCode,"small":""}},[_vm._v("mdi-palette-swatch-variant")]),_vm._v(_vm._s(_vm.$store.getters.getTextMap().item_code)+" ")],1)],1),_c('router-link',{style:({
                color: 'inherit',
                display: 'flex',
                'justify-content': 'space-between',
                'align-item': 'center',
                'text-decoration': 'none',
              }),attrs:{"to":'/derived/location'}},[_c('v-tab',[_c('v-icon',{staticClass:"mr-2",attrs:{"isDark":_vm.$store.getters.getColorPalette().isDark,"color":_vm.$store.getters.getColorPalette().accentCode,"small":""}},[_vm._v("mdi-map-marker")]),_vm._v(_vm._s(_vm.$store.getters.getTextMap().locations)+" ")],1)],1),_c('router-link',{style:({
                color: 'inherit',
                display: 'flex',
                'justify-content': 'space-between',
                'align-item': 'center',
                'text-decoration': 'none',
              }),attrs:{"to":'/derived/locationEventType'}},[_c('v-tab',[_c('v-icon',{staticClass:"mr-2",attrs:{"isDark":_vm.$store.getters.getColorPalette().isDark,"color":_vm.$store.getters.getColorPalette().accentCode,"small":""}},[_vm._v("mdi-move-resize-variant")]),_vm._v(_vm._s(_vm.$store.getters.getTextMap().locations_event_types)+" ")],1)],1),_c('router-link',{style:({
                color: 'inherit',
                display: 'flex',
                'justify-content': 'space-between',
                'align-item': 'center',
                'text-decoration': 'none',
              }),attrs:{"to":'/derived/lists'}},[_c('v-tab',[_c('v-icon',{staticClass:"mr-2",attrs:{"isDark":_vm.$store.getters.getColorPalette().isDark,"color":_vm.$store.getters.getColorPalette().accentCode,"small":""}},[_vm._v("mdi-format-list-bulleted")]),_vm._v(_vm._s(_vm.$store.getters.getTextMap().lists)+" ")],1)],1),_c('router-link',{style:({
                color: 'inherit',
                display: 'flex',
                'justify-content': 'space-between',
                'align-item': 'center',
                'text-decoration': 'none',
              }),attrs:{"to":'/derived/dependent_lists'}},[_c('v-tab',[_c('v-icon',{staticClass:"mr-2",attrs:{"isDark":_vm.$store.getters.getColorPalette().isDark,"color":_vm.$store.getters.getColorPalette().accentCode,"small":""}},[_vm._v("mdi-graph-outline")]),_vm._v(_vm._s(_vm.$store.getters.getTextMap().dependent)+" "+_vm._s(_vm.$store.getters.getTextMap().lists)+" ")],1)],1),_c('router-link',{style:({
                color: 'inherit',
                display: 'flex',
                'justify-content': 'space-between',
                'align-item': 'center',
                'text-decoration': 'none',
              }),attrs:{"to":'/derived/equations'}},[_c('v-tab',[_c('v-icon',{staticClass:"mr-2",attrs:{"isDark":_vm.$store.getters.getColorPalette().isDark,"color":_vm.$store.getters.getColorPalette().accentCode,"small":""}},[_vm._v("mdi-plus-minus-variant")]),_vm._v(_vm._s(_vm.$store.getters.getTextMap().param_equations)+" ")],1)],1),_c('router-link',{style:({
                color: 'inherit',
                display: 'flex',
                'justify-content': 'space-between',
                'align-item': 'center',
                'text-decoration': 'none',
              }),attrs:{"to":'/derived/comboParams'}},[_c('v-tab',[_c('v-icon',{staticClass:"mr-2",attrs:{"isDark":_vm.$store.getters.getColorPalette().isDark,"color":_vm.$store.getters.getColorPalette().accentCode,"small":""}},[_vm._v("mdi-vector-combine")]),_vm._v(_vm._s(_vm.$store.getters.getTextMap().combo_params)+" ")],1)],1),_c('router-link',{style:({
                color: 'inherit',
                display: 'flex',
                'justify-content': 'space-between',
                'align-item': 'center',
                'text-decoration': 'none',
              }),attrs:{"to":'/derived/calculatedParams'}},[_c('v-tab',[_c('v-icon',{staticClass:"mr-2",attrs:{"isDark":_vm.$store.getters.getColorPalette().isDark,"color":_vm.$store.getters.getColorPalette().accentCode,"small":""}},[_vm._v("mdi-calculator-variant-outline")]),_vm._v(_vm._s(_vm.$store.getters.getTextMap().calculated_params)+" ")],1)],1),_c('router-link',{style:({
                color: 'inherit',
                display: 'flex',
                'justify-content': 'space-between',
                'align-item': 'center',
                'text-decoration': 'none',
              }),attrs:{"to":'/derived/sheets'}},[(
                  _vm.$store.state.user &&
                  _vm.$store.getters.getAccessByLevels(['engineeraccess'])
                )?_c('v-tab',[_c('v-icon',{staticClass:"mr-2",attrs:{"isDark":_vm.$store.getters.getColorPalette().isDark,"color":_vm.$store.getters.getColorPalette().accentCode,"small":""}},[_vm._v("mdi-clipboard-text-outline")]),_vm._v(_vm._s(_vm.$store.getters.getTextMap().sheets)+" ")],1):_vm._e()],1),_c('router-link',{style:({
                color: 'inherit',
                display: 'flex',
                'justify-content': 'space-between',
                'align-item': 'center',
                'text-decoration': 'none',
              }),attrs:{"to":'/derived/ESGScopeConversion'}},[(
                  _vm.$store.state.user &&
                  _vm.$store.getters.getAccessByLevels(['engineeraccess'])
                )?_c('v-tab',[_c('v-icon',{staticClass:"mr-2",attrs:{"isDark":_vm.$store.getters.getColorPalette().isDark,"color":_vm.$store.getters.getColorPalette().accentCode,"small":""}},[_vm._v("mdi-pine-tree-variant-outline")]),_vm._v(_vm._s(_vm.$store.getters.getTextMap().esg_scope_logging)+" ")],1):_vm._e()],1),_c('router-link',{style:({
                color: 'inherit',
                display: 'flex',
                'justify-content': 'space-between',
                'align-item': 'center',
                'text-decoration': 'none',
              }),attrs:{"to":'/derived/targets'}},[(
                  _vm.$store.state.user &&
                  _vm.$store.getters.getAccessByLevels(['engineeraccess'])
                )?_c('v-tab',[_c('v-icon',{staticClass:"mr-2",attrs:{"isDark":_vm.$store.getters.getColorPalette().isDark,"color":_vm.$store.getters.getColorPalette().accentCode,"small":""}},[_vm._v("mdi-target")]),_vm._v(_vm._s(_vm.$store.getters.getTextMap().targets)+" ")],1):_vm._e()],1),_c('router-link',{style:({
                color: 'inherit',
                display: 'flex',
                'justify-content': 'space-between',
                'align-item': 'center',
                'text-decoration': 'none',
              }),attrs:{"to":'/derived/tariff'}},[(
                  _vm.$store.state.user &&
                  _vm.$store.getters.getAccessByLevels(['engineeraccess'])
                )?_c('v-tab',[_c('v-icon',{staticClass:"mr-2",attrs:{"isDark":_vm.$store.getters.getColorPalette().isDark,"color":_vm.$store.getters.getColorPalette().accentCode,"small":""}},[_vm._v("mdi-currency-rupee")]),_vm._v(_vm._s(_vm.$store.getters.getTextMap().tariffs)+" ")],1):_vm._e()],1),_c('router-link',{style:({
                color: 'inherit',
                display: 'flex',
                'justify-content': 'space-between',
                'align-item': 'center',
                'text-decoration': 'none',
              }),attrs:{"to":'/derived/words'}},[(
                  _vm.$store.state.user &&
                  _vm.$store.state.settingMode &&
                  _vm.$store.getters.getAccessByLevels([
                    'engineeraccess',
                    'betaaccess' ])
                )?_c('v-tab',[_c('v-icon',{staticClass:"mr-2",attrs:{"isDark":_vm.$store.getters.getColorPalette().isDark,"color":_vm.$store.getters.getColorPalette().accentCode,"small":""}},[_vm._v("mdi-file-word-box")]),_vm._v(_vm._s(_vm.$store.getters.getTextMap().words)+" ")],1):_vm._e()],1),_c('router-link',{style:({
                color: 'inherit',
                display: 'flex',
                'justify-content': 'space-between',
                'align-item': 'center',
                'text-decoration': 'none',
              }),attrs:{"to":'/derived/OEMDevices'}},[(
                  _vm.$store.state.user &&
                  _vm.$store.state.settingMode &&
                  _vm.$store.getters.getAccessByLevels([
                    'engineeraccess',
                    'alphaaccess' ])
                )?_c('v-tab',[_c('v-icon',{staticClass:"mr-2",attrs:{"isDark":_vm.$store.getters.getColorPalette().isDark,"color":_vm.$store.getters.getColorPalette().accentCode,"small":""}},[_vm._v("mdi-fan")]),_vm._v(_vm._s(_vm.$store.getters.getTextMap().oem_devices)+" ")],1):_vm._e()],1)],1),_c('v-divider',{attrs:{"color":_vm.$store.getters.getColorPalette().accentCode}})],1),_c('router-view')],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }